import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Notifier } from '../utils/notifier';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ApiClientService {

  constructor(
    private http: HttpClient,
    private notifier: Notifier) { }

  get(path: string, params?: HttpParams): Observable<any> {
    return this.http.get<object[]>(
      path, {
        params: params ? params : null
      });
  }

  post(path: string, body: any): Observable<any> {
    return this.http.post(
      path,
      body,
      {responseType: 'text'});
  }

  put(path: string, body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token',
        responseType: 'text'
      }),
    };
    return this.http.put(
      path,
      body,
      httpOptions);
  }

  delete(path: string) {
    return this.http.delete(
      path,
      {responseType: 'text'});
  }

}
