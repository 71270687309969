import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Notifier } from 'src/app/utils/notifier';
import { ProductService } from '../../../services/product.service';
import { Product } from '../product';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {

  form: FormGroup;
  product: Product;
  fileToUpload: File;
  uploading: boolean;
  create: boolean;

  public constructor(
    private service: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private notifier: Notifier,
    private fb: FormBuilder) {
    this.product = {
      canEdit: false,
      id: null,
      title: '',
      description: ''
    };
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      image: [''],
      webAppUrl: ['']
    });

    const id = this.route.snapshot.paramMap.get('id');
    this.create = id === 'create';
    if (this.create) {
      // We are creating a new product!
      this.create = true;
      this.product.canEdit = true;
    } else {
      this.service.getProduct(id).subscribe(product => {
        this.product = product;
        this.form.patchValue({
          title: product.title,
          image: product.imageName ? product.imageName : '',
          description: product.description,
          webAppUrl: product.webAppUrl ? product.webAppUrl : ''
        });
      }, (errorMsg) => {
        this.notifier.error('Failed to edit product', errorMsg);
        this.router.navigate(['/products']);
      });
    }
  }

  updateFile(files: FileList) {
    if (files.length === 1) {
      const file: File = files.item(0);
      const imageName = file.name;
      this.fileToUpload = file;
      this.product.imageName = imageName;
    }
  }

  cancel() {
    this.router.navigate(['/products']);
  }

  deleteImage() {
    this.fileToUpload = null;
    this.product.imageName = null;
  }

  delete() {
    if (confirm('Are you sure you want to delete this product?')) {
      const product = this.product;
      this.service.delete(product).subscribe(ret => {
        this.notifier.success('Product deleted');
        this.router.navigate(['/products']);
      }, (errorMsg) => {
        this.notifier.error('Failed to delete product', errorMsg);
      });
    }
  }

  saveProduct() {
    const formGroup: FormGroup = this.form;
    const product = this.product;
    product.title = formGroup.controls.title.value;
    product.description = formGroup.controls.description.value;
    product.webAppUrl = formGroup.controls.webAppUrl.value;
    if (this.fileToUpload) {
      this.uploading = true;
    }
    if (this.create) {
      this.service.create(product, this.fileToUpload).subscribe(id => {
        this.notifier.success('Product created');
        this.router.navigate(['/products']);
      }, (errorMsg) => {
        this.notifier.error('Failed to create product', errorMsg);
        this.uploading = false;
      });
    } else {
      this.service.update(product, this.fileToUpload).subscribe(_ => {
        this.notifier.success('Product updated');
        this.router.navigate(['/products']);
        this.uploading = false;
      }, (errorMsg) => {
        this.notifier.error('Failed to update product', errorMsg);
      });
    }
  }

}
