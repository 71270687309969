/** Example component for demonstrating various features of the SINTEFBasic application and the wonka-ui component library
 *  If needed, code from this component can be used. In that case, the component (with all 4 files) must be renamed, and
 *  references in app.module.ts must be updated.
 */
import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Action, Card } from '@sintef/wonka-ui';
import { Subscription } from 'rxjs';
import { Notifier } from 'src/app/utils/notifier';
import { Utils } from 'src/app/utils/utils';
import { ProductService } from '../../../services/product.service';
import { Product } from '../product';
import { Account } from 'msal';


/** Component for calling an API endpoint to get a list of products and showing it in a table.
 *  Showing the list in a TableModule from wonka-ui.
 */
@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})

export class ProductListComponent implements OnInit, OnDestroy {

  /** Subscription(s) for subscribing to any observables in async calls */
  subscription: Subscription = new Subscription();

  @Output() cards: Card<Product>[] = [];

  finished: boolean;

  showContactInfo = false;
  showSwaggerViewer = false;

  constructor(
    private productService: ProductService,
    private utils: Utils,
    private router: Router,
    private notifier: Notifier,
    private msalService: MsalService) { }

  ngOnInit() {
    this.getProducts();
  }

  /** Subscribe to products using the product API endpoint
   */
  private getProducts(): void {
    this.subscription.add(this.productService.getProducts().subscribe((products) => {
      this.cards = products.map((product: Product) => this.createCard(product));
      const canAddProduct = this.msalService.getAccount() != null;
      if (canAddProduct) {
        this.cards.unshift(this.createAddProductCard());
      }
    }, (error) => {
      console.log(error);
      this.notifier.error('message.error.getProductsError', error);
      this.finished = true;
    }));

    this.productService.getProducts();
  }

  createAddProductCard(): Card<Product> {
    const product = {
      title: '',
      description: '',
    } as Product;

    const card = {
      input: null,
      imageUrl: null,
      title: 'Add new product',
      description: '',
      actions: [new Action('Add', () => this.edit(product))]
    } as Card<Product>;
    return card;
  }

  /**
   * Create card from product
   */
  private createCard(product: Product): Card<Product> {
    const card: Card<Product> = {
      input: product,
      title: product.title,
      description: product.description,
      content: product.description,
      actions: []
    };
    if (product.imageUrl) {
      card.imageUrl = product.imageUrl;
    }
    if (product.webAppUrl) {
      card.actions.push(this.createWebAppAction(product));
    }
    if (product.apiUrl) {
      card.actions.push(this.createAPIAction(product));
    }
    if (product.contactInfo) {
      card.actions.push(this.createContactInfo(product));
    }

    const canEdit = this.canEdit(product);
    if (canEdit) {
      card.actions.push(new Action('Edit', () => this.edit(product)));
    }

    return card;
  }

  canEdit(product: Product): boolean {
    const userId = this.currentUser();
    if (userId == null) {
      return false;
    }
    return product.canEdit;
  }

  currentUser() {
    const user: Account = this.msalService.getAccount();
    if (user == null) {
      return null;
    }
    const res: { sub: string } = JSON.parse(JSON.stringify(user.idToken));
    const userId = res.sub;
    return userId;
  }


  edit(product: Product) {
    if (product.id) {
      this.router.navigate(['/products/' + product.id]);
    } else {
      this.router.navigate(['/products/create']);
    }
  }

  private createWebAppAction(product: Product): Action {
    return new Action('Web application', () => {
      window.open(product.webAppUrl);
    });
  }

  private createAPIAction(product: Product): Action {
    return new Action('API', () => {
      this.showSwaggerViewer = true;
    });
  }

  private createContactInfo(product: Product): Action {
    return new Action('Contact info', () => {
      this.showContactInfo = true;
    });
  }


  /** Unsubscribing from all observables to prevent memory leaks. */
  ngOnDestroy(): void {
    this.utils.closeSubscriptions(this.subscription);
  }

  hideModal() {
    this.showContactInfo = false;
    this.showSwaggerViewer = false;
  }
}
