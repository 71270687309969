import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BroadcastService, MsalInterceptor, MsalService } from '@azure/msal-angular';
import 'rxjs/add/observable/fromPromise';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomHttpInterceptor extends MsalInterceptor {

  private msalService: MsalService;

  constructor(msalService: MsalService,
              broadcastService: BroadcastService) {
    super(msalService, broadcastService);
    this.msalService = msalService;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const isLoggedIn = this.msalService.getAccount() != null;
    if (!isLoggedIn) {
      // Allow anonymous users to access reduced functionality
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }));
    }
    return super.intercept(request, next);
  }
}
