import { Injectable, ViewChild, OnDestroy } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Provides a set of functions for showing formatted info messages to the user.
 *
 * Uses angular-notifier library
 *
 * Import NotifierModule.withConfig({ }) in app.module to use
 */
@Injectable({
    providedIn: 'root'
})
export class Notifier implements OnDestroy {

    @ViewChild('customNotification', { static: true }) customNotificationTmpl;

    customNotificationImpl: string;
    subscription: Subscription = new Subscription();

    constructor(
        private notifier: NotifierService,
        private translate: TranslateService) { }

    /**
     * Provides a success message to the user.
     *
     * Example: this.notifier.success('The operation was successful');
     * @param message   Message to provide to the user
     */
    success(message: string): void {
        this.show('success', message, true);
    }

    /**
     * Provides an info message to the user.
     *
     * Example: this.notifier.info('The operation is finished');
     * @param message   Message to provide to the user
     */
    info(message: string): void {
        this.show('info', message, true);
        this.log(3, message, true);
    }

    /**
     * Provides a warning message to the user and logs the message.
     *
     * Example: this.notifier.warning('The operation was unsuccessful');
     * @param message   Message to provide to the user
     */
    warning(message: string): void {
        this.show('warning', message, false);
        this.log(2, message, true);
    }

    /**
     * Provides an error message to the user and logs error object if provided.
     *
     * Example: this.notifier.error('Something went wrong', error);
     * @param message   Message to provide to the user
     * @param error     Optional error object
     */
    error(message: string, error?: Error): void {
        this.show('error', message, false);
        this.log(1, error, true);
    }

    /**
     * Closes all open messages (success/info/warning/error).
     *
     * Example: this.notifier.close();
     */
    close(): void {
        this.notifier.hideAll();
    }

    /**
     * Provides a success message to the user if a logical statement is true.
     *
     * Example: this.notifier.success('The operation was successful', operationIsOK);
     * @param message   Message to provide to the user
     * @param ok        True/false flag
     */
    successIfTrue(message: string, ok: boolean): void {
        this.ifTrue('success', message, ok, true);
    }

    /**
     * Provides an info message to the user if a logical statement is true.
     *
     * Example: this.notifier.info('The operation is finished', operationIsOK);
     * @param message   Message to provide to the user
     * @param ok        True/false flag
     */
    infoIfTrue(message: string, ok: boolean): void {
        this.ifTrue('info', message, ok, true);
        this.log(2, message, ok);
    }

    /**
     * Provides a warning message to the user and logs the message if a logical statement is true.
     *
     * Example: this.notifier.warning('The operation was unsuccessful', operationFailed);
     * @param message   Message to provide to the user
     * @param ok        True/false flag
     */
    warningIfTrue(message: string, ok: boolean): void {
        this.ifTrue('warning', message, ok, false);
        this.log(1, message, ok);
    }

    /**
     * Provides an error message to the user and logs provided error object if a logical statement is true.
     *
     * Example: this.notifier.error('Something went wrong', error);
     * @param message   Message to provide to the user
     * @param error     Optional error object
     * @param ok        True/false flag
     */
    errorIfTrue(message: string, ok: boolean, error?: Error): void {
        this.ifTrue('error', message, ok, false);
        this.log(0, error, ok);
    }

    private log(level: number, msg: (string | Error), ok: boolean): void {
        if (msg && ok) {
            if (environment.config && environment.config.notifierLogLevel >= level) {
                console.log(this.translate.instant(msg.toString()));
                const header = 'LOG ClientId: ' + environment.config.clientID;
                let errorLog: string[];
                if (localStorage.getItem(header)) {
                    errorLog = [(localStorage.getItem(header)), this.translate.instant(JSON.stringify(msg))];
                } else {
                    errorLog = [this.translate.instant(JSON.stringify(msg))];
                }
                localStorage.setItem(header, errorLog.toString());
            }
        }
    }

    private ifTrue(type, message: string, ok: boolean, hideAll: boolean): void {
        if (ok) {
            this.show(type, message, hideAll);
        } else {
            this.notifier.hideAll();
        }
    }

    private show(type: string, message: string, hideAll: boolean): void {
        if (hideAll) {
            this.notifier.hideAll();
        }
        this.subscription.add(this.translate.get(message).subscribe((translatedMessage) => {
            this.notifier.show({
                message: this.translate.instant(translatedMessage),
                type
            });
        }));
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
