import { LogLevel } from 'msal';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const apiRoot = '/api/';
const redirectUri = location.origin;
const postLogoutRedirectUri = redirectUri;


// See env.js
const env = 'env';

export const environment = {

  production: true,

  config: {
    /** Top menu in navigation bar.
     * @naame {string}: Visible name
     * @path {string}: Route to component as described in app-routing.module.ts
     */
    menu: [
      // {name: 'Home', path: '/'}
    ],

    /** Workflow menu in left column
     *  @appRoot {string} - Application root
     *  @workflowClass {string} - Class for styling the workflow. Should be implemented in src/styles.scss.
     *  @buttonClass {string} - Class for styling each workflow button. Should be implemented in src/styles.scss
     *  @active {string} - The id of the active WorkflowSteps
     *  @workflowSteps {WorkflowStep[]} - The WorkflowSteps in the Workflow
     */
    workflow: {
      appRoot: redirectUri,
      workflowClass: 'workflow',
      buttonClass: 'workflow-button',
      active: '1',
      workflowSteps: [
        { id: '1', name: 'Home', path: '' },
        { id: '2', name: 'Products', path: '/products' }
      ]
    },
    clientID: '760b5b12-eb9a-44d1-a778-407d8bb94bf9',
    authority: 'https://login.microsoftonline.com/e1f00f39-6041-45b0-b309-e0210d8b32af',
    validateAuthority: true,
    redirectUri,
    postLogoutRedirectUri,
    navigateToLoginRequestUrl: false,
    consentScopes: [
      'email',
      'user.read',
      'openid',
      'profile',
    ],
    popUp: false,
    unprotectedResources: ['https://www.microsoft.com/en-us/'],
    storeAuthStateInCookie: true,
    correlationId: '1000',
    piiLoggingEnabled: true,
    level: LogLevel.Info,
    notifierLogLevel: LogLevel.Info
  },
  // Declare the URLs to all API endpoints here.
  apis: {
    products: apiRoot + 'products'
  },
  defaultLanguage: 'en'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
