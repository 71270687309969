import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../pages/product/product';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  constructor(
    private service: ApiClientService) { }

  getProducts(): Observable<Product[]> {
    const apiEndpointUrl = environment.apis.products;
    return this.service.get(apiEndpointUrl);
  }

  getProduct(id: string): Observable<Product> {
    const apiEndpointUrl = environment.apis.products + '/' + id;
    return this.service.get(apiEndpointUrl);
  }

  /**
   * Create Product with initial values
   * @param product Initial value
   * @returns product ID
   */
  create(product: Product, imageFile: File): Observable<string> {
    return this.service.post(environment.apis.products, product).pipe(map(ret => {
      const idObj = JSON.parse(ret);
      return idObj.id;
    })).pipe(mergeMap(id => this.uploadFileIfNeeded(id, imageFile)));
  }

  uploadFileIfNeeded(productId: string, fileToUpload: File): Observable<string> {
    if (fileToUpload) {
      // We need to send it first
      return this.sendImage(productId, fileToUpload).pipe(map(res => productId));
    }
    return of(productId);
  }

  update(product: Product, imageFile: File) {
    const apiEndpointUrl = environment.apis.products + '/' + product.id;
    return this.service.put(apiEndpointUrl, product).pipe(mergeMap(_ => this.uploadFileIfNeeded(product.id, imageFile)));
  }

  delete(product: Product): Observable<string> {
    const apiEndpointUrl = environment.apis.products + '/' + product.id;
    return this.service.delete(apiEndpointUrl);
  }

  sendImage(productId: string, file: File): Observable<any> {
    const apiEndpointUrl = environment.apis.products + '/' + productId + '/image';
    const frmData: FormData = new FormData();
    frmData.append(file.name, file);
    return this.service.post(apiEndpointUrl, frmData);
  }

}
