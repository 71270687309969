import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationBarModule, InfoPanelModule, TableModule, FormModule, CardModule } from '@sintef/wonka-ui';
import { CommonModule } from '@angular/common';
import { MsalModule } from '@azure/msal-angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { NotifierModule } from 'angular-notifier';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PrivateComponent } from './pages/private/private.component';
import { PublicComponent } from './pages/public/public.component';
import { ProductListComponent } from './pages/product/product-list/product-list.component';
import { ApiClientService } from './services/api-client.service';
import { CustomHttpInterceptor } from './services/custom-http-interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SpinnerComponent } from './utils/spinner/spinner.component';
import { WorkflowComponent } from './workflow/workflow.component';
import { SwaggerViewerComponent } from './pages/swagger-viewer/swagger-viewer.component';
import { ProductEditComponent } from './pages/product/product-edit/product-edit.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductService } from './services/product.service';

@NgModule({
  declarations: [
    AppComponent,
    PrivateComponent,
    PublicComponent,
    WorkflowComponent,
    SpinnerComponent,
    ProductListComponent,
    SwaggerViewerComponent,
    ProductEditComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    NavigationBarModule,
    TableModule,
    CardModule,
    InfoPanelModule,
    FormModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'left'
        },
        vertical: {
          distance: 10,
          position: 'bottom'
        },
      },
      behaviour: {
        autoHide: 7000
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MsalModule.forRoot({
      auth: {
        clientId: environment.config.clientID,
        authority: environment.config.authority,
        validateAuthority: environment.config.validateAuthority,
        redirectUri: environment.config.redirectUri,
        postLogoutRedirectUri: environment.config.postLogoutRedirectUri,
        navigateToLoginRequestUrl: environment.config.navigateToLoginRequestUrl,
      },
      cache: {
        cacheLocation: 'sessionStorage',
      },
    },
    {
      popUp: environment.config.popUp,
      consentScopes: environment.config.consentScopes,
      protectedResourceMap: [
        ['https://graph.microsoft.com/v1.0/me', ['user.read']]
      ],
      unprotectedResources: environment.config.unprotectedResources,
      extraQueryParameters: {}
    }),
    MatButtonModule,
    MatDialogModule,
    FormsModule
  ],
  providers: [
    ApiClientService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    ProductService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ProductEditComponent] // To enable use in dynamic modal dialog
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
