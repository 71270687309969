import { Component, OnInit, Input } from '@angular/core';
import { SwaggerUIBundle } from 'swagger-ui-dist';

@Component({
  selector: 'app-swagger-viewer',
  templateUrl: './swagger-viewer.component.html'
})
export class SwaggerViewerComponent implements OnInit {

  @Input() url: string;

  constructor() { }

  ngOnInit() {
    SwaggerUIBundle({
      url: this.url,
      dom_id: '#swagger-viewer',
    });
  }

}
