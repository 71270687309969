import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

export interface WorkflowStep {
  id: string;
  name: string;
  path: string;
  disabled?: boolean;
}

export interface Workflow {
  appRoot: string;
  workflowSteps: WorkflowStep[];
  active: string;
  workflowClass: string;
  buttonClass: string;
}

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {

  @Output() workflowStepClicked: EventEmitter<WorkflowStep> = new EventEmitter<WorkflowStep>();
  @Input() workflow: Workflow;
  @Input() workflowClass: string;
  @Input() type: number;

  subscription: Subscription = new Subscription();

  constructor() { }

  ngOnInit() {
    this.workflow.active = this.getWorkflow(window.location.href);
    if (!this.workflowClass) {
      this.workflowClass = '';
    }
  }

  stepClicked(step: WorkflowStep) {
    this.workflowStepClicked.emit(step);
  }

  private getWorkflow(base: string): string {
    const path = base.replace(this.workflow.appRoot, '').split('/', 3)[1];
    if (path.length > 0) {
      const wf = this.workflow.workflowSteps.find(o => {
        return '/' + path === o.path;
      });
      if (wf) {
        return wf.id;
      }
    }
    return '1';

  }

}
