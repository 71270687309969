import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivateComponent } from './pages/private/private.component';
import { MsalGuard } from '@azure/msal-angular';
import { PublicComponent } from './pages/public/public.component';
import { ProductListComponent } from './pages/product/product-list/product-list.component';
import { ProductEditComponent } from './pages/product/product-edit/product-edit.component';


const routes: Routes = [
  { path: 'public', component: PublicComponent },
  { path: '', component: PrivateComponent, canActivate: [MsalGuard] },
  { path: 'products', component: ProductListComponent, canActivate: [MsalGuard] },
  { path: 'products/:id', component: ProductEditComponent, canActivate: [MsalGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
