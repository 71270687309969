import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Workflow, WorkflowStep } from '../workflow/workflow.component';

@Injectable({
    providedIn: 'root'
})
export class Utils {

    constructor(
        private router: Router) { }

    /** Navigates to another route
     *  @route {string} - Route to navigate to
     *  @parameter {string} Optional route parameter
     *
     * @example
     *  openPage('page-detail', '123');
     */
    openPage(route: string, parameter?: string[]): void {
        if (parameter) {
            this.router.navigate([route + parameter]);
        } else {
            this.router.navigate([route]);
        }
    }

    /** Closes any subscriptions
     *  @subscription {Subscription} - The subscriptions to close
     *
     *  @example
     *  closeSubscriptions(subscription);
     */
    closeSubscriptions(subscription: Subscription) {
        if (subscription) {
            subscription.unsubscribe();
        }
    }

    /** Navigates to a defined WorkflowStep, represented by a route
     *  @workflow {Workflow} - The workflow, presumably defined in environment.ts
     *  @path {string} - The path of the WorkflowStep to navigate to
     *
     *  @example
     *  openWorkflow(workflow, '/products');
     */
    openWorkflow(workflow: Workflow, route: string) {
        const step: WorkflowStep = workflow.workflowSteps.find(o => {
            return o.path === route;
        });
        if (step) {
            workflow.active = step.id;
            for (const wf of workflow.workflowSteps) {
              wf.disabled = false;
            }
            this.openPage(step.path ? step.path : '/');
        }
    }

    /** Disables a number of WorkflowSteps in a Workflow
     *  @workflow {Workflow} - The workflow, presumably defined in environment.ts
     *  @steps {string[]} - The ids of the WorkflowSteps that should be disabled
     *
     *  @example
     *  disableWorkflowSteps(workflow, ['1', '2']);
     */
    disableWorkflowSteps(workflow: Workflow, steps: string[]): void {
        for (const step of steps) {
            const workflowStep: WorkflowStep = workflow.workflowSteps.find(o => {
                return o.id === step;
            });
            if (workflowStep) {
                workflowStep.disabled = true;
            }
        }
    }
}
