import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html'
})
export class PublicComponent implements OnInit {

  constructor(
    private msalService: MsalService,
    private router: Router) { }

  ngOnInit() {
    if (this.msalService.getAccount() !== null) {
      this.router.navigate(['/']);
    }
  }

}
