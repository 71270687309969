import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Notifier } from './utils/notifier';
import { Account } from 'msal';
import { Title } from '@angular/platform-browser';
import { Workflow, WorkflowStep } from './workflow/workflow.component';
import { Utils } from './utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  showContent: boolean;
  title = '';
  consentScopes = environment.config.consentScopes;

  workflow: Workflow = environment.config.workflow;

  menu = environment.config.menu;

  loggedIn: boolean;
  user: {
    name: string;
    email: string;
  };
  subscription: Subscription = new Subscription();

  constructor(
    private translate: TranslateService,
    private msalService: MsalService,
    private broadcastService: BroadcastService,
    private titleService: Title,
    private notifier: Notifier,
    private utils: Utils) {
      this.translate.setDefaultLang(environment.defaultLanguage);
      this.subscription.add(this.translate.get('app.title').subscribe((title) => {
        this.title = title;
        this.titleService.setTitle(title);
      }));
    }

  ngOnInit() {

    this.user = this.getUser(this.msalService.getAccount());
    if (!this.user) {
      this.utils.openPage('public/');
      this.loggedIn = false;
      this.showContent = true;
    } else {
      this.loggedIn = true;
      this.showContent = true;
    }

    this.subscription.add(this.broadcastService.subscribe('msal:loginSuccess',
    () => {
      this.loggedIn = true;
      this.user = this.getUser(this.msalService.getAccount());
      const accessTokenRequest = {
        scopes: environment.config.consentScopes
      };
    }));
    this.subscription.add(this.broadcastService.subscribe('msal:loginFailure',
    (error) => {
      this.loggedIn = false;
      this.notifier.error('message.login.failure', error);
      this.user = this.getUser(this.msalService.getAccount());
    }));


  }

  authorize(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.msalService.logout();
    } else {
      this.msalService.loginRedirect();
    }
  }

  private getUser(user: Account) {
    return !user ? undefined : {
      name: user.name,
      email: user.userName
    };
  }

  openWorkflow(step: WorkflowStep) {
    this.utils.openWorkflow(this.workflow, step.path);
  }

  ngOnDestroy() {
    // disconnect from broadcast service on component destroy
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
